import axios from "axios";

// List of all languages hosted on personal Github

export function fetchLanguages(cancelToken) {
  return new Promise((resolve, reject) => {
    axios
      .get("https://raw.githubusercontent.com/stijnvanlieshout/ISO-639-1-json-list/master/languages.json", cancelToken ? {cancelToken} : {})
      .then((result) => resolve(result.data))
      .catch((error) => reject(error));
  });
}
