// React
import React from "react";
import {navigate} from "gatsby";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import MuiLink from "@material-ui/core/Link";

import {
  Typography
} from "@material-ui/core";
import {NavigateNext} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: ({topGutterSpacing, bottomGutterSpacing}) => ({
    // borderBottom: `1px solid ${theme.palette.borders}`
    display: "flex",
    marginTop: theme.spacing(topGutterSpacing || 0),
    marginBottom: theme.spacing(bottomGutterSpacing || 0),
  }),
  crumbContainer: {
    display: "flex",
  },
  crumbArrow: {
    margin: "auto",
    opacity: 0.8,
    fontSize: 24,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  crumbText: {
    marginTop: "auto",
    marginBottom: "auto",
    // fontWeight: 700
  },
  crumbLink: {
    "&:hover": {
      opacity: 0.8,
    },
  }
}));


export default function NavBreadCrumbs(props) {
  const {crumbs} = props;
  const classes = useStyles(props);

  const handleLinkClick = destination => navigate(destination);

  return (
    <div className={classes.root}>
      {crumbs
        .map((crumb, idx) => {
          const {label, icon, destination} = crumb;
          const isLastCrumb = crumbs.length === (idx + 1);
          return (
            <div key={label} className={classes.crumbContainer}>
              {icon ? icon : ""}
              <Typography variant="body2" className={classes.crumbText}>
                {destination ? (<MuiLink
                    onClick={() => handleLinkClick(destination)}
                    color="textPrimary"
                    underline="always"
                    className={classes.crumbLink}
                  >
                    {label}
                  </MuiLink>
                ) : label}
              </Typography>
              {!isLastCrumb && <NavigateNext className={classes.crumbArrow}/>}
            </div>
          )
        })}
    </div>
  );
}