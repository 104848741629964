export const skillOptions = [
  {
    value: "googleAds",
    label: "Google ads"
  }, {
    value: "facebookAds",
    label: "Facebook ads"
  }, {
    value: "linkedInAds",
    label: "LinkedIn Ads"
  }, {
    value: "googleAnalytics",
    label: "Google analytics"
  }, {
    value: "youtubeAds",
    label: "Youtube ads"
  }, {
    value: "googleSearchConsole",
    label: "Google search console"
  }, {
    value: "twitterAds",
    label: "Twitter ads"
  }, {
    value: "mailchimp",
    label: "Mailchimp"
  }, {
    value: "customerio",
    label: "Customer IO"
  }, {
    value: "hubspot",
    label: "Hubspot"
  }, {
    value: "salesForce",
    label: "Salesforce"
  }, {
    value: "quoraAds",
    label: "Quora ads"
  }, {
    value: "instagramAds",
    label: "Instagram Ads"
  }, {
    value: "bingAds",
    label: "Bing ads"
  }, {
    value: "adroll",
    label: "Adroll"
  }, {
    value: "redditAds",
    label: "Reddit ads"
  }, {
    value: "spotifyAds",
    label: "Spotify ads"
  }, {
    value: "amazonAdvertising",
    label: "Amazon advertising"
  }, {
    value: "googleShopping",
    label: "Google shopping"
  }, {
    value: "contentCreation",
    label: "Content creation"
  }, {
    value: "socialMediaManagement",
    label: "Social media management"
  }, {
    value: "outbrain",
    label: "Outbrain"
  }, {
    value: "pinterestAds",
    label: "Pinterest ads"
  }, {
    value: "taboola",
    label: "Taboola"
  }, {
    value: "appleSearchAds",
    label: "Apple search ads"
  }, {
    value: "tikTokAds",
    label: "TikTok ads"
  }, {
    value: "snapchatAds",
    label: "Snapchat ads"
  }, {
    value: "affiliateMarketing",
    label: "Affiliate marketing"
  }, {
    value: "branding",
    label: "Branding"
  }, {
    value: "chatbotMarketing",
    label: "Chatbot marketing"
  }, {
    value: "communityManagement",
    label: "Community management"
  }, {
    value: "copywriting",
    label: "Copywriting"
  }, {
    value: "crmImplementation",
    label: "CRM implemenation"
  }, {
    value: "converionRateOptimization",
    label: "CRO (Converion rate optimization)"
  }, {
    value: "graphicDesign",
    label: "Graphic design"
  }, {
    value: "emailMarketing",
    label: "Email marketing"
  }, {
    value: "influencerMarketing",
    label: "Influencer marketing"
  }, {
    value: "marketingStrategy",
    label: "Marketing strategy"
  }, {
    value: "searchEngineOptimization",
    label: "SEO (search engine optimization)"
  }, {
    value: "searchEngineAdvertising",
    label: "SEA (search engine advertising)"
  }, {
    value: "smsMarketing",
    label: "SMS marketing"
  }, {
    value: "appStoreOptimization",
    label: "App store optimization"
  }, {
    value: "trackingImplementation",
    label: "Tracking implemenation"
  }, {
    value: "googleTagManager",
    label: "Google tag manager"
  }, {
    value: "webDesign",
    label: "Web design"
  }, {
    value: "webDevelopment",
    label: "Web development"
  }, {
    value: "prototyping",
    label: "Prototyping"
  }, {
    value: "uxDesign",
    label: "UX design"
  }, {
    value: "uiDesign",
    label: "UI design"
  }, {
    value: "dataAnalysis",
    label: "dataAnalysis"
  }, {
    value: "c",
    label: "C"
  }, {
    value: "python",
    label: "python"
  }, {
    value: "java",
    label: "java"
  }, {
    value: "javascript",
    label: "javascript"
  }, {
    value: "C++",
    label: "C++"
  }, {
    value: "C#",
    label: "C#"
  }, {
    value: "visualBasic",
    label: "Visual basic"
  }, {
    value: "php",
    label: "PHP"
  }, {
    value: "sql",
    label: "SQL"
  }, {
    value: "r",
    label: "R"
  }, {
    value: "objectiveC",
    label: "Objective-C"
  }, {
    value: "typeScript",
    label: "TypeScript"
  }, {
    value: "swift",
    label: "Swift"
  }, {
    value: "kotlin",
    label: "Kotlin"
  }, {
    value: "matlab",
    label: "Matlab"
  }, {
    value: "go",
    label: "Go"
  }, {
    value: "vba",
    label: "VBA"
  }, {
    value: "ruby",
    label: "Ruby"
  }, {
    value: "scala",
    label: "scala"
  }, {
    value: "rust",
    label: "Rust"
  }, {
    value: "ada",
    label: "Ada"
  }, {
    value: "dart",
    label: "Dart"
  }, {
    value: "lua",
    label: "Lua"
  }, {
    value: "cobol",
    label: "COBOL"
  }, {
    value: "groovy",
    label: "Groovy"
  }, {
    value: "abap",
    label: "ABAP"
  }, {
    value: "scrum",
    label: "Scrum"
  }, {
    value: "perl",
    label: "Perl"
  }, {
    value: "julia",
    label: "Julia"
  }, {
    value: "haskell",
    label: "Haskell"
  }, {
    value: "sketch",
    label: "Sketch"
  }, {
    value: "invision",
    label: "InVision"
  }, {
    value: "figma",
    label: "Figma"
  }, {
    value: "framer",
    label: "Framer"
  }, {
    value: "adobeSuit",
    label: "Adobe Suit"
  }, {
    value: "adobeIllustrator",
    label: "Adobe Illustrator"
  }, {
    value: "adobePhotoshop",
    label: "Adobe Photoshop"
  }, {
    value: "adobeInDesign",
    label: "Adobe InDesign"
  }, {
    value: "adobePremier",
    label: "Adobe Premier"
  }, {
    value: "adobeXd",
    label: "Adobe XD"
  }, {
    value: "referralMarketing",
    label: "Referral Marketing"
  }, {
    value: "animation",
    label: "Animation"
  }, {
    value: "illustration",
    label: "Illustration"
  }, {
    value: "storyTelling",
    label: "Story telling"
  }, {
    value: "onlineMarketing",
    label: "Online marketing"
  }, {
    value: "googleCloud",
    label: "Google cloud"
  }, {
    value: "amazonWebServices",
    label: "Amazon web services"
  }, {
    value: "devOps",
    label: "Dev ops"
  }, {
    value: "gatsby",
    label: "Gatsby"
  }, {
    value: "machineLearning",
    label: "Machine learning"
  }, {
    value: "ai",
    label: "AI"
  }
]


