// DevOps: 
// If underConstruction == true, then app is not accesible
export const underConstruction = false;

// Pricing configs
export const serviceFeeRate = 4;
export const minBillAmountExclEur = 10000;
export const minHourlyRateEur = 25;
export const maxHourlyRateEur = 500;

// Team requirements 
export const minTeamMembers = 3;

// Shown on company invoices
export const maxerBillingDetails = {
    businessName: "Growthbase",
    taxNumber: "NL200086327B02",
    city: "Sint Anthonis",
    country: "Netherlands",
    postcode: "5845BD",
    addressLineOne: "Molenstraat 11",
    addressLineTwo: null,
};