// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import {Typography, Divider, Avatar, Card, CardContent, CardHeader, CardActions} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

// Constants
import {countryToFlag, getCurrencyFormat} from "../../utils/formatting";
import CardActionArea from "@material-ui/core/CardActionArea";

const useStyles = makeStyles((theme) => ({
  root: ({topGutterSpacing, bottomGutterSpacing}) => ({
    marginTop: theme.spacing(topGutterSpacing || 0),
    marginBottom: theme.spacing(bottomGutterSpacing || 0)
  }),
  cardHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  hourlyRateText: {
    padding: theme.spacing(3),
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  cardActionsContainer: {
    float: "right",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
}));

export default function FreelancerCard(props) {
  const {freelancer, hideAbout, CardActionsContent, handleActionAreaClick} = props;
  const {about, profilePhoto, firstName, lastName, country, headline, hourlyRate, currency} = freelancer;
  const classes = useStyles(props);

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={handleActionAreaClick}>
        <div className={classes.cardHeaderContainer}>
          <CardHeader
            avatar={
              (profilePhoto) ? (
                <Avatar src={profilePhoto}/>
              ) : (
                <Avatar>
                  {firstName.substring(0, 1)}
                </Avatar>
              )
            }
            title={`${firstName} ${lastName} ${countryToFlag(country)}`}
            // disableTypography
            subheader={headline}
          />
          <Typography className={classes.hourlyRateText}>
            {`${getCurrencyFormat(hourlyRate, currency, {convertToDecimals: true})}/hour`}
          </Typography>
        </div>
        {!hideAbout && <Divider/>}
        {!hideAbout && (
          <CardContent>
            <Typography variant="body2">
              {about}
            </Typography>
          </CardContent>
        )}
      </CardActionArea>
      {CardActionsContent && <Divider/>}
      {CardActionsContent && (
        <CardActions className={classes.cardActionsContainer}>
          {CardActionsContent}
        </CardActions>
      )}
    </Card>
  );
}

FreelancerCard.propTypes = {
  freelancer: PropTypes.object.isRequired,
  hideAbout: PropTypes.bool,
  CardActionsContent: PropTypes.any,
};
