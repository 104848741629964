// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import {
  DialogTitle,
  Avatar,
  CardHeader,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Close} from "@material-ui/icons";

// Constants
import {countryToFlag} from "../../utils/formatting";
import {FirebaseContext} from "../Firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: 'space-between',
  },
  cardHeaderRoot: {
    padding: 0,
    marginRight: theme.spacing(2)
  },
  closeIconButton: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  }
}));

export default function FreelancerDialogTitle(props) {
  const {freelancer, handleClose} = props;
  const classes = useStyles(props);
  const firebase = React.useContext(FirebaseContext);

  const expandFreelancer = Boolean(typeof freelancer === 'string');
  const [freelancerData, setFreelancerData] = React.useState(expandFreelancer ? null : freelancer);
  const [loading, setLoading] = React.useState(expandFreelancer);

  React.useEffect(() => {
    if (expandFreelancer) {
      firebase.user(freelancer)
        .get()
        .then(snap => {
          setFreelancerData(firebase.formatSnapshot(snap));
          setLoading(false);
        });
    }
  }, [firebase, expandFreelancer, freelancer]);

  if (loading) {
    return (
      <DialogTitle
        id="freelancer-dialog-title"
        disableTypography
        className={classes.root}
      >
        <LinearProgress/>
      </DialogTitle>
    )
  } else if (!freelancerData) {
    return <div>Something went wrong</div>
  }

  const {firstName, lastName, profilePhoto, headline, country} = freelancerData;
  return (
    <DialogTitle
      id="freelancer-dialog-title"
      disableTypography
      className={classes.root}
    >
      <CardHeader
        avatar={
          (profilePhoto) ? (
            <Avatar src={profilePhoto} className={classes.avatar}/>
          ) : (
            <Avatar className={classes.avatar}>
              {firstName.substring(0, 1)}
            </Avatar>
          )
        }
        title={`${firstName} ${lastName} ${countryToFlag(country)}`}
        titleTypographyProps={{variant: "h6", component: "h1"}}
        subheader={headline}
        classes={{root: classes.cardHeaderRoot}}
      />
      <IconButton onClick={handleClose} className={classes.closeIconButton}>
        <Close/>
      </IconButton>
    </DialogTitle>
  );
}

FreelancerDialogTitle.propTypes = {
  freelancer: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};
