// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import {Typography, LinearProgress, Box} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {grey} from "@material-ui/core/colors";

// Constants
import {skillOptions} from "../../constants/skills";
import StatusLabel from "../StatusLabel";
import classNames from "classnames";
import {getCurrencyFormat} from "../../utils/formatting";
import axios from "axios";
import {fetchLanguages} from "../../services/languages";
import {AuthUserContext} from "../Session";

function useFetchLanguages() {
  const [value, setValue] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const loadData = () => {
      fetchLanguages(source.token)
        .then(result => {
          console.log("result", result);
          setValue(result);
          setLoading(false);
        });
    };
    loadData();
    return () => {
      source.cancel();
    };
  }, []);

  console.log("fetching languages");
  return [value, loading];
}

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
  },
  label: {
    fontWeight: 800,
    marginRight: theme.spacing(1),
    marginTop: "auto",
    marginBottom: "auto",
    opacity: 0.6,
  },
  topGutter: {
    marginTop: theme.spacing(2),
  },
  linearProgress: {
    width: '100%',
    maxWidth: theme.spacing(20),
    marginTop: "auto",
    marginBottom: "auto",
  }
}));

export default function FreelancerProfile(props) {
  const {freelancer, team} = props; //reviews
  const {about, skills, hourlyRate} = freelancer;
  const classes = useStyles(props);
  const theme = useTheme();
  const authUser = React.useContext(AuthUserContext);
  const isTeamLead = (team && team.teamLeadId) ? (team.teamLeadId === (authUser && authUser.uid)) : false;
  const [languages, loadingLanguages] = useFetchLanguages();

  const selectedLanguages = freelancer.languages
    .map(flCode => languages.filter(({code}) => code === flCode)[0])
    .filter(v => v);

  const selectedSkills = skills
    .map(s => skillOptions.filter(({value}) => value === s)[0])
    .filter(v => v);


  const teamMembership = team ? team.members[freelancer.uid] : null;
  console.log("teamMembership", teamMembership);
  const teamMembershipStatus = teamMembership ? teamMembership.status : null;
  console.log("teamMembershipStatus", teamMembershipStatus);
  return (
    <Box>
      <Typography>{about}</Typography>
      {teamMembershipStatus && (
        <div className={classNames(classes.flexContainer, classes.topGutter)}>
          <Typography className={classes.label} variant="body2">Membership status:</Typography>
          <div className={classes.flexContainer}>
            <StatusLabel
              color={theme.palette.statusColors[teamMembershipStatus]}
              value={teamMembershipStatus}
              size="small"
              shape="square"
            />
            {(isTeamLead && freelancer.uid !== authUser.uid && teamMembershipStatus === "active") && (
              <Typography
                variant="caption"
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginLeft: theme.spacing(1)
                }}
              >
                Want to deactivate this membership? Please contact support.
              </Typography>
            )}
            {/*{teamMembershipStatus !== "left" && (*/}
            {/*  <Link*/}
            {/*    onClick={teamMembershipStatus === "active" ? handleDeactivateMember : handleActivateMember}*/}
            {/*    style={{*/}
            {/*      color: theme.palette.error.main,*/}
            {/*      marginTop: "auto",*/}
            {/*      marginBottom: "auto",*/}
            {/*      marginLeft: theme.spacing(1)*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    {teamMembershipStatus === "active" ? "Deactivate membership" : "Reactivate membership"}*/}
            {/*  </Link>*/}
            {/*)}*/}
          </div>
        </div>
      )}
      <div className={classNames(classes.flexContainer, classes.topGutter)}>
        <Typography className={classes.label} variant="body2">Hourly rate:</Typography>
        <Typography
          className={classes.flexContainer}
          variant="body1"
        >
          {`${getCurrencyFormat(hourlyRate, freelancer.currency, {convertToDecimals: true})}`}
        </Typography>
      </div>
      <div className={classNames(classes.flexContainer, classes.topGutter)}>
        <Typography className={classes.label} variant="body2">Languages:</Typography>
        {loadingLanguages ? (
          <LinearProgress className={classes.linearProgress}/>
        ) : (
          <div className={classes.flexContainer}>
            {selectedLanguages.map((l, idx) =>
              <StatusLabel
                color={grey[700]}
                value={l.name}
                shape="square"
                size="small"
                leftGutterSpacing={idx > 0 ? 1 : 0}
                key={l.code}
              />
            )}
          </div>
        )}
      </div>
      <div className={classNames(classes.flexContainer, classes.topGutter)}>
        <Typography className={classes.label} variant="body2">Skills:</Typography>
        <div className={classes.flexContainer}>
          {selectedSkills.map((s, idx) =>
            <StatusLabel
              color={grey[700]}
              value={s.label}
              shape="square"
              size="small"
              leftGutterSpacing={idx > 0 ? 1 : 0}
              key={s.value}
            />
          )}
        </div>
      </div>
    </Box>
  );
}

FreelancerProfile.propTypes = {
  freelancer: PropTypes.object.isRequired,
  team: PropTypes.object,
};
