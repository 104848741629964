// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import {
  Divider,
  Chip,
  Typography,
  Popover,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

// Utils
import {hexToRgb} from "../../utils/colors";
import {camelToSentenceText} from "../../utils/formatting";
import {Info} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  labelRoot: ({variant, color, topGutterSpacing, bottomGutterSpacing, leftGutterSpacing, rightGutterSpacing, shape, height}) => ({
    height: height || 32,
    background:
      variant === "outlined"
        ? "inherit"
        : `rgba(${hexToRgb(color)}, ${theme.palette.statusLabelOpacity})`,
    color: color,
    borderColor: color,
    borderRadius: shape === "square" ? 4 : false,
    marginLeft: theme.spacing(leftGutterSpacing || 0),
    marginRight: theme.spacing(rightGutterSpacing || 0),
    marginTop: (topGutterSpacing || bottomGutterSpacing) ? theme.spacing(topGutterSpacing || 0) : "auto",
    marginBottom: (topGutterSpacing || bottomGutterSpacing) ? theme.spacing(bottomGutterSpacing || 0) : "auto",
    "&:hover": {
      background: `rgba(${hexToRgb(color)}, ${
        theme.palette.statusLabelOpacity
      })`,
    },
    "&:focus": {
      background: `rgba(${hexToRgb(color)}, ${
        theme.palette.statusLabelOpacity
      })`,
      boxShadow: "none",
    },
  }),
  chip: {
    display: "flex",
  },
  label: ({shape, icon, size}) => ({
    paddingLeft: shape === "square" ? 8 : icon ? 6 : 12,
    paddingRight: shape === "square" ? 8 : icon ? 6 : 12,
  }),
  statusLabelText: ({size}) => ({
    // opacity: 0.7,
    fontSize: size === "small" ? "0.6rem" : "0.7rem",
  }),
  statusValueText: ({size, fontWeight}) => ({
    fontWeight: fontWeight || "inherit",
    fontSize: size === "small" ? "0.7rem" : "0.85rem",
    margin: "auto",
  }),
  divider: ({size, color}) => ({
    width: 1,
    height: size === "small" ? 12 : 20,
    marginBottom: "auto",
    marginTop: "auto",
    marginLeft: 7,
    marginRight: 7,
    background: `rgba(${hexToRgb(color)}, 0.5)`,
  }),
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  infoPopoverIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginBottom: "auto",
    marginTop: "auto",
    marginLeft: 4
  }
}));

export default function StatusLabel(props) {
  const {label, icon, value, clickable, size, variant, popoverText} = props;
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.root}>
      <Chip
        id={`status-label-${label}`}
        icon={icon || null}
        label={
          <div className={classes.chip}>
            {label && (
              <Typography variant="overline" className={classes.statusLabelText}>
                {label}
              </Typography>
            )}
            {label && <Divider className={classes.divider}/>}
            <Typography variant="caption" className={classes.statusValueText}>
              {camelToSentenceText(value)}
            </Typography>
            {popoverText && (
              <Info
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                className={classes.infoPopoverIcon}
              />
            )}
          </div>
        }
        clickable={clickable}
        size={size || "medium"}
        variant={variant || "default"}
        classes={{
          root: classes.labelRoot,
          label: classes.label,
        }}
      />
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography variant="caption">{popoverText}</Typography>
      </Popover>
    </div>
  );
}

StatusLabel.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string.isRequired,
  clickable: PropTypes.bool,
  size: PropTypes.string,
  popoverText: PropTypes.string,
};
