// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import {
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
} from "@material-ui/core";

// Constants
import FreelancerDialogTitle from "./FreelancerDialogTitle";

export default function FreelancerDialog(props) {
  const {freelancer, handleClose, DialogBodyContent, DialogActionsContent} = props;

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Dialog
      fullScreen={!isDesktop}
      open={true}
      onClose={handleClose}
      scroll="paper"
      maxWidth="lg"
      style={{maxWidth: 800, minWidth: isDesktop ? 800 : 0, margin: "auto"}}
    >
      <FreelancerDialogTitle
        freelancer={freelancer}
        handleClose={handleClose}
      />
      {DialogBodyContent && (
        <DialogContent dividers>
          {DialogBodyContent}
        </DialogContent>
      )}
      {DialogActionsContent && (
        <DialogActions>
          {DialogActionsContent}
        </DialogActions>
      )}
    </Dialog>
  );
}

FreelancerDialog.propTypes = {
  freelancer: PropTypes.object.isRequired,
};
